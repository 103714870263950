export enum Route {
  About = '/about',
  Home = '/home',
  Login = '/login',
  WaitingList = '/waiting',
  NotFound = '/404',
  Maintenance = '/maintenance',
  Create = '/create',
  Edit = '/edit',
  Error = '/error',
  Preset = '/preset',
  View = '/view',
}

export enum SortOptions {
  LastModified = 'lastModified',
  OldestModified = 'oldestModified',
  AlphabeticallyAToZ = 'aToZ',
  AlphabeticallyZToA = 'zToA',
}

export enum FeedState {
  Published = 'published',
  Ready = 'ready',
  Draft = 'draft',
}

export enum FeedOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum RobinBtnStyle {
  Primary = 'primary',
  Outline = 'outline',
  Destructive = 'destructive',
  Disabled = 'disabled',
}

export enum NavBtnStyle {
  Transparent = 'transparent',
  Default = 'default',
  Active = 'active',
  Danger = 'danger',
}

export enum ActionMenuItemStyle {
  Default = 'default',
  Active = 'active',
  Danger = 'danger',
}

export enum ErrorActionType {
  Retry = 'retry',
  Back = 'back',
  Home = 'home',
}

export enum Shape {
  Circle = 'circle',
  Square = 'square',
}
